export const BoxTypes = [ 
    { label: 'Testo', value: 1 },
    { label: 'Servizi (Card)', value: 2 },
    { label: 'Team (Card)', value: 3 },
    { label: 'Giorni lavorativi', value: 4 },
    { label: 'Catalogo Prodotti ', value: 5 },
    { label: 'Blog', value: 6 },
    { label: 'Google Maps', value: 7 },
    { label: 'Immagine', value: 8 },
    { label: 'Testo + Immagine', value: 9 },
    { label: 'Contattaci', value: 10 },
    { label: 'Facebook Feed', value: 11 },
    { label: 'Instagram Feed', value: 12 },
    { label: 'Video Youtube', value: 13 },
    { label: 'Percorso', value: 14 },    
    { label: 'Slide', value: 15 },
    { label: 'IFrame', value: 16 },    
    { label: 'Prodotto', value: 17 }   
]